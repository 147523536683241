<template>
  <div class="repair-apply-page wrapper page-wrap">
    <a-spin :spinning="loadingForm">
      <a-form :model="form" ref="formRef" :colon="colon" @finish="handleSubmit">
        <div class="repair-wrap">
          <a-row type="flex" justify="space-between" :gutter="20">
            <a-col :span="12">
              <a-form-item
                name="repair_type_id"
                label="上报类型"
                :rules="{ required: true, message: '请选择上报类型' }"
              >
                <a-select
                  v-model:value="form.repair_type_id"
                  placeholder="请选择上报类型"
                  size="large"
                  
                >
                  <a-select-option
                    v-for="(item, index) in repair_type"
                    :value="item.repair_type_id"
                    :key="index"
                    >{{ item.repair_type_name }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="site_id"
                label="房间"
                :rules="{ required: true, message: '请选择房间' }"
              >
                <a-select
                  v-model:value="form.site_id"
                  placeholder="请选择房间"
                  size="large"
                >
                  <a-select-option
                    v-for="(item, index) in site"
                    :value="item.site_id"
                    :key="index"
                    >{{ item.site_name }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
            <!-- <a-col :span="12">
              <a-form-item
                name="repair_address"
                label="房间/位置"
                :rules="{
                  required: true,
                  message: '请写明具体房间号或位置信息'
                }"
              >
                <a-input
                  v-model:value="form.repair_address"
                  size="large"
                  placeholder="请写明具体房间号或位置信息"
                />
              </a-form-item>
            </a-col> -->
            <a-col :span="12">
              <a-form-item
                name="repair_contact_name"
                label="联系人"
                :rules="{
                  required: true,
                  message: '请输入'
                }"
              >
                <a-input
                  v-model:value="form.repair_contact_name"
                  size="large"
                  placeholder="请输入"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="repair_contact_phone"
                label="联系电话"
                :rules="{
                  required: true,
                  message: '请输入联系电话'
                }"
              >
                <a-input
                  v-model:value="form.repair_contact_phone"
                  size="large"
                  placeholder="请输入"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item
                name="repair_text"
                :rules="{ required: true, message: '请输入内容' }"
              >
                <a-textarea
                  v-model:value="form.repair_text"
                  size="large"
                  :auto-size="{ minRows: 4, maxRows: 4 }"
                  placeholder="输入内容......"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item name="repair_image">
                <upload-image
                  v-model:imageList="form.repair_image"
                  :imageCount="9"
                  :couldRename="false"
                  @change="getImageStatus('enterprise_logo')"
                ></upload-image>
              </a-form-item>
            </a-col>
          </a-row>
        </div>
        <a-form-item class="">
          <a-button
            block
            size="large"
            type="primary"
            html-type="submit"
            class="btn"
            :loading="loadingBtn"
            @click="handleSubmit"
          >
            确认提交
          </a-button>
        </a-form-item>
      </a-form>
    </a-spin>
  </div>
</template>
<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
  ref
} from "@vue/runtime-core";
import {
  getRepairTypeList,
  insertRepairData,
  getRepairSiteList
} from "@/api/main.js";
import { uploadFile } from "@/utils/ali-oss/upload.js";
import { getUserId, getUserData } from "@/utils/storeData.js";
import url from "@/utils/url.js";
import { message } from "ant-design-vue";
import { encode } from "js-base64";
import uploadImage from "@/components/upload.vue";

export default defineComponent({
  name: "RepairApply",
  components: {
    uploadImage
  },
  setup() {
    const formRef = ref();
    const pageData = reactive({
      colon: false,
      autosize: false,
      repair_type: [],
      site: [],
      form: {
        repair_type_id: null,
        repair_address: null,
        repair_contact_name: null,
        repair_contact_phone: null,
        repair_text: null,
        repair_image: null,
        site_id: null
      },
      previewVisible: false,
      loadingBtn: false,
      loadingForm: false,
      user_id: computed(() => getUserId()),
      userData: computed(() => getUserData())
    });

    const getRepairTypeListFun = () => {
      getRepairTypeList()
        .then(res => {
          if (res.code == 200) {
            pageData.repair_type = res.data.repair_type;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        });
    };
    getRepairTypeListFun();
    const getRepairSiteListFun = () => {
      getRepairSiteList({
        user_id:pageData.user_id,
        park_id:pageData.userData?.user_park_id
      })
        .then(res => {
          if (res.code == 200) {
            pageData.site = res.data.site;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        });
    };
    getRepairSiteListFun();
    const handleSubmit = e => {
      e.preventDefault();
      formRef.value
        .validate()
        .then(res => {
          pageData.loadingBtn = true;
          pageData.loadingForm = true;
          insertRepairData({
            ...res,
            user_id: pageData.user_id,
            park_id: pageData.userData?.user_park_id,
            repair_image: pageData.form.repair_image,
            repair_address:pageData.site.find(v=>v.site_id==res.site_id).site_name
          }).then(res => {
            message.success("提交成功");
            url.navigateTo("/repair/detail", {
              t: encode(`${res.data.repair_id}`)
            });
          });
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {
          pageData.loadingBtn = false;
          pageData.loadingForm = false;
        });
    };

    const getImageStatus = e => {
      formRef.value.clearValidate(e);
    };

    return {
      ...toRefs(pageData),
      formRef,
      handleSubmit,
      getImageStatus
    };
  }
});
</script>
<style lang="less">
.repair-apply-page {
  .repair-wrap {
    width: 960px;
    height: 360px;
    background-color: #ffffff;
    border-radius: 10px;
    margin: 40px auto;
    padding: 20px;
    box-sizing: border-box;
  }
}
</style>
